import { StyleSheet, Button } from 'react-native';

import EditScreenInfo from '../components/EditScreenInfo';
import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';

export default function TabOneScreen({ navigation }: RootTabScreenProps<'TabOne'>) {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>NamishGram</Text>
      <Text style={styles.inspire}>The best way to master English grammar is here! Practice hundreds of questions and improve your vocabulary too!</Text>
      <button
   title="Learn More"
   color="#841584"
   style={styles.button}
>Start now</button>
      <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 28,
    fontWeight: 'bold',
  },
  inspire: {
      fontSize: 20,
      fontWeight:"normal",
      marginTop: 12.5,
      textAlign:"center",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  button: {
        backgroundColor:"#ff0077",
        outline:"none",
        border:"none",
        padding:"15px",
        color:"white",
        marginTop:12.5,
        width:"12.5em",
        fontSize:17.5,
        borderRadius:360,
  }
});
